import { useEffect } from 'react'
import socketIOClient from 'socket.io-client'
import { dispatch } from 'store'
import { handleDatagridSocketCells, handleDatagridSocketGridData } from '../tailwindui/pages/datagrid/api'

var msgs = []
let socket

const WebSocket = (user) => {
    const token = localStorage.getItem('accessToken')
    const userId = user._id
    const init = async () => {
        // Configure socket with auth
        socket = socketIOClient.connect(process.env.REACT_APP_API_URL, {
            auth: {
                userId,
                token
            },
            autoConnect: true,
            reconnection: true,
            reconnectionAttempts: 5, // Number of attempts before giving up
            reconnectionDelay: 3000, // Time to wait between attempts (1 second)
            reconnectionDelayMax: 5000, // Max delay (5 seconds)
            timeout: 20000, // Time before the connection attempt times out (20 seconds)
        })
        socket.on('connect', () => {
            console.log('Socket connected:', socket.id)
            socket.emit('register', userId);
            socket.on('private_message', (data) => {
                msgs.push(data)
                dispatch({ type: 'SET_SOCKET_DATA', payload: data })
                handleDatagridSocketCells(data)
                if(data.message?.dataType === 'TABLE_STATUS') {
                    console.log('TABLE_STATUS', data)
                    dispatch({ type: 'TABLE_STATUS', payload: data })
                }
                if(data.message?.dataType === 'UPDATE_TABLE_STATUS') {
                    dispatch({ type: 'SET_ENRICHMENT_STATUS', payload: data?.isProcessing === true ? 'STARTED': 'STOPPED' })
                }
            })
            
            dispatch({ type: 'SET_SOCKET_ID', payload: socket.id })
            dispatch({ type: 'SET_ROOM_ID', payload: 'private_message' })
        })

        socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error.message)
            dispatch({ type: 'SOCKET_ERROR', payload: error.message })
        })
        // socket.on('private_message', (private_message) => {
        //     console.log('private_message', private_message)
        // })

        socket.on('unauthorized', (error) => {
            console.error('Socket authentication failed:', error.message)
            dispatch({ type: 'SOCKET_UNAUTHORIZED', payload: error.message })
            socket.disconnect()
        })
        socket.on('reconnect_attempt', (attempt) => {
            console.log(`Reconnection attempt ${attempt}`);
        });
        
        socket.on('reconnect', (attempt) => {
            console.log(`Successfully reconnected after ${attempt} attempts`);
        });
        socket.on('server_restart', () => {
            console.warn('Server was restarted. Reinitializing...');
            // Handle necessary reinitialization here
        });
    }

    useEffect(() => {
        if (userId && token) {
            init()
        } else {
            console.error('Socket initialization failed: Missing userId or token')
        }

        const cl = setInterval(() => {
            if (msgs.length > 0) {
                const msgLength = msgs.length
                // get all message till length and remove them from array
                const getmsgs = msgs.slice(0, msgLength)
                msgs = msgs.slice(msgLength)
                handleDatagridSocketGridData(getmsgs)
            }
        }, 1000)

        return () => {
            if (socket) {
                socket.disconnect()
                dispatch({ type: 'CLEANUP_SOCKET' })
            }
            clearInterval(cl)
        }
    }, [userId, token])

    return {
        socket
    }
}
export default WebSocket